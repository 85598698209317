const envConfig = {
  REACT_APP_API_TOKEN: process.env.REACT_APP_API_TOKEN ?? 'c6e5be5365984c75af234452e18a9b0c',
  REACT_APP_API_URL: process.env.REACT_APP_API_URL ?? 'https://grocefy-dev-api.azurewebsites.net/api/',
  REACT_APP_CLIENT_ID: process.env.REACT_APP_CLIENT_ID ?? 'c6e5be5365984c75af234452e18a9b0c',
  REACT_APP_HUB_URL: process.env.REACT_APP_HUB_URL ?? 'https://grocefy-dev-api.azurewebsites.net/',
  REACT_APP_GEO_API_KEY: process.env.REACT_APP_GEO_API_KEY ?? 'AIzaSyB6jl09IPMyXwc55wjIj40Mi9p2ABtZKf0',
  REACT_APP_PREFIX: process.env.REACT_APP_PREFIX ?? 'grocefy-gopanza',

  REACT_APP_DEFAULT_LANGUAGE: process.env.REACT_APP_DEFAULT_LANGUAGE ?? 'en',

  REACT_APP_SERVER_CONFIG: process.env.REACT_APP_SERVER_CONFIG ?? 'Endpoint=https://grocefy-dev-config.azconfig.io;Id=gAo5-l0-s0:Jftj3Xq4Vqhv87DCceQD;Secret=+q1t5zgKMg0Bsj9PhOlzothY8eUve+nDNmVRMhNEGLM=',
  REACT_APP_APP_CONFIG: process.env.REACT_APP_APP_CONFIG ?? 'Endpoint=https://grocefy-app-dev-config.azconfig.io;Id=vd/p-l4-s0:IlCN65pePUIPl/hNTPpR;Secret=4p2ScPjC4RmgAxWmwpnfErcyvrOn/x4jbgKYHQkX7BY=',
  REACT_APP_MANAGER_CONFIG: process.env.REACT_APP_MANAGER_CONFIG ?? 'Endpoint=https://grocefy-manager-dev.azconfig.io;Id=DV0C;Secret=12JKM3GrsnusXyuiknaO34e3pmPKBmJElA96YdedxtqTkFF43uJvJQQJ99AGACHYHv6cLlZEAAACAZAChQue',

  REACT_APP_IMAGES_URL: process.env.REACT_APP_IMAGES_URL ?? 'https://grocefy-dev.azure-api.net/images/',
  REACT_APP_IMAGES_KEY: process.env.REACT_APP_IMAGES_KEY ?? 'c6e5be5365984c75af234452e18a9b0c',
  REACT_APP_UTILITY_KEY: process.env.REACT_APP_UTILITY_KEY ?? 'utility-b60bbed139054e2289dfa5d29e4c63aab495f236d3af48168378c41dc671d643',
  REACT_APP_PAYMENTS_KEY: process.env.REACT_APP_PAYMENTS_KEY ?? '9f09ee1311b041afad4a09b53bce41022e952f1576be4933b63a3af9e1860745bf6642961a6e45aba8f05363ca875473c6a4b854e1ab40e19c79b033b3f5a17e',
  REACT_APP_BOOKING_API_URL: process.env.REACT_APP_BOOKING_API_URL ?? '#{REACT_APP_BOOKING_API_URL}#',
  REACT_APP_BOOKING_API_KEY: process.env.REACT_APP_BOOKING_API_KEY ?? '#{REACT_APP_BOOKING_API_KEY}#',
  REACT_APP_TIMEZONE_OFFSET: process.env.REACT_APP_TIMEZONE_OFFSET ?? '-4',
  REACT_APP_BOOKING_CANCELLATION_FEE: process.env.REACT_APP_BOOKING_CANCELLATION_FEE ?? '0',
  BookingEventUrl: process.env.REACT_APP_BOOKING_API_EVENT_URL ?? '#{REACT_APP_BOOKING_API_EVENT_URL}#',
  SpecificProductUrl: process.env.REACT_APP_SPECIFIC_PRODUCT_URL ?? '#{REACT_APP_SPECIFIC_PRODUCT_URL}#',
  WebsiteUrl: process.env.REACT_APP_WEBSITE_URL ?? '#{REACT_APP_WEBSITE_URL}#'

};

export default envConfig;
